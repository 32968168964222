import React from "react"
import {Typography} from "@sivasifr/ui-core/Typography"
import {Card} from "../components/Card"

const ArticlesList = ({ posts, title }) => {
  return (
    <>
    <Typography
      component="h1"
      itemProp="headline"
      className="mt-3"
    >
      {title}
    </Typography>
    <div className="row mt-3" style={{ listStyle: `none` }}>
      {Object.entries(posts).map(([key, post]) => {
        return (
          <div key={post.id} className="col-md-4">
            <Card
              {...post}
              slug={`/article/${post.id.toLowerCase().replace(/[.,\/#!$%&*;:{}=_`~()]/g,'')}`}
            />
          </div>
        )
      })}
    </div>
  </>
  )
}

export default ArticlesList