import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import ArticlesList from "./ArticlesList"

const PageTags = ({ data, location }) => {

  const siteTitle = `Title`
  const posts = data.allPost.nodes
  const pageTitle = data.allTag.nodes[0].label

  if (posts == null) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={pageTitle} />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={pageTitle} />
      <div className="container">
        <ArticlesList
          posts={posts}
          title={pageTitle}
        />
      </div>
    </Layout>
  )
}

export default PageTags

export const tagsPageQuery = graphql`
  query TagsPageQuery($tagId: [String]) {
    allTag(filter: {id: {in: $tagId}}) {
      nodes {
        label
      }
    }
    allPost(
     filter: {tags: {elemMatch: {id: {in: $tagId}}}}
    ) {
      nodes {
        id
        title
        timeUpdate
        logLine
        poster
        tags {
          id
          label
        }
      }
    }
  }
`